<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="contract"
                :search="search"
                class="elevation-1"
                style="background: #f5f5f5"
                single-expand
                :expanded.sync="expanded"
                show-expand
              
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <b>Id: </b> {{ item.id }} <br />
                    <b>Question : </b> {{ item.question }}<br />
                    <b>Ans: </b> {{ item.ans }}<br />
                    <b>Date : </b> {{ item.date }}<br />
                  </td>
                </template>

                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2 ml-2"
                      >Questions And Ans</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <!--                 
                    <div>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        filled
                        rounded
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                    <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer> -->

                    <v-dialog
                      :close-on-content-click="false"
                      v-model="dialog"
                      scrollable
                      transition="scale-transition"
                      origin="top right"
                      :nudge-left="500"
                      max-width="500px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2 mt-2 mr-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="white">mdi-plus</v-icon>Add NEW
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title justify="center">
                          <span
                            ><p class="font-weight-bold" Roboto>
                              Add FAQ
                            </p></span
                          >
                        </v-card-title>

                        <v-card-text>
                          <v-form ref="productForm">
                            <v-row class="pt-7">
                              <v-col cols="12">
                                <v-textarea
                                  rows="1"
                                  auto-grow
                                  outlined
                                  v-model="editedItem.question"
                                  label="Question"
                                ></v-textarea>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea
                                  auto-grow
                                  v-model="editedItem.ans"
                                  label="Ans"
                                  filled
                                ></v-textarea>
                              </v-col>
                            </v-row>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="red" text @click="close"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="confirm"
                                >Save</v-btn
                              >
                            </v-card-actions>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <!-- FAQ Edit dialog -->

                    <v-dialog v-model="dialog2" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit FAQ</span>
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.question"
                                label="Question"
                                outlined
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-textarea
                                v-model="editedItem.ans"
                                label="Ans"
                                filled
                                auto-grow
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red" text @click="close">Cancel</v-btn>
                          <v-btn
                            color="blue darken-1"
                            elevation="0"
                            @click="saveEdit"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    snackbar: false,
    text: "",
    dialog: false,
    dialog2: false,
    question: "",
    ans: "",
    headers: [
      {
        text: "Question And Ans",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Question", value: "question", sortable: false },
      { text: "Ans", value: "ans", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    contract: [],
    contact: {},
    editedIndex: -1,

    editedItem: {
      question: "",
      ans: "",
    },

    defaultItem: {
      question: "",
      ans: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New FAQ" : "Edit FAQ";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
  },

  methods: {
    initialize() {
      axios.get("site/show_faq/").then((response) => {
        this.contract = response.data.data;
      });
    },

    addnew() {
      this.dialog2 = true;
    },

    confirm() {
      axios
        .post("site/insert_faq/", this.editedItem)
        .then((response) => {
          if (response.data.success) {
            this.contract.push(response.data.data);
            this.dialog = false;
            this.text = "Faq inserted successfully";
            this.snackbar = true;
            this.editedItem = {};
          }
        })
        .catch((err) => {
          this.dialog = false;
          this.text = "Something went wrong !";
          this.snackbar = true;
        });
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog2 = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`site/delete_faq/${item.id}/`).then((response) => {
          this.contract = this.contract.filter(
            (contact) => contact.id !== item.id
          );
        });
      }
    },

    close() {
      this.dialog = false;
      this.dialog2 = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      axios
        .post(`site/specific_faq/${this.editedItem.id}/`, this.editedItem)
        .then((response) => {
          this.dialog2 = false;
          this.text = "Faq updated successfully";
          this.snackbar = true;
          this.editedItem = {};
        })
        .catch((err) => {
          this.dialog2 = false;
          this.text = "Something went wrong !";
          this.snackbar = true;
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>
